
  import { defineComponent,getCurrentInstance} from 'vue'


  export default defineComponent({
    name: '',
    props: {
      canShowModal:{
        type: Boolean,
        default: false
      }
    },
    components: {},
    setup: () => {
    }
  })
